body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Making Front Size Consistent*/
.MuiFormLabel-root{
  font-size: 1.5rem !important;
  line-height: .5 !important;
 
 }

.userDescThemeFont{
    font-size:0.9em !important;
}
.userDescValueFont{
    font-size:0.8em !important;
}

/* Commenting out following CSS configurations for PR 254 */
/*
.enter-from-right {
  width: 100%;
  -webkit-transition: transform 0.5s; /* For Safari 3.1 to 6.0 
  transition: transform 0.5s;
  -webkit-transform: translateX(100%);
}

.enter-from-left {
  width: 100%;
  position: absolute !important;
  -webkit-transition: transform 0.5s; /* For Safari 3.1 to 6.0 
  transition: transform 0.5s;
  -webkit-transform: translateX(-100%);
}

.slide-in{
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
} */

.image-center{
  display:block!important;
  margin-left: auto !important;
  margin-right: auto!important;
  float: none !important;
}

.imagecentercontainer{
  display: flex;
  justify-content: center;
}

.navbar-nav{
  text-align: center;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 1px;
    background: #00000000; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #337ab7aa; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #337ab7; 
}
