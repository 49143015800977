/*!* Desktop *!*/
/*@media screen and (min-width: 980px)  {*/
/*    .content{*/
/*        padding-top : 0;*/
/*    }*/
/*}*/

/*!* Tablet *!*/
/*@media screen  and (max-width: 979px)  {*/
/*    .content{*/
/*        padding-top : 10%;*/
/*    }*/
/*}*/

/*!* Mobile *!*/
/*@media screen and (max-width: 500px)  {*/
/*    .content{*/
/*        padding-top : 20%;*/
/*    }*/
/*}*/