@import url('https://fonts.googleapis.com/css?family=Ubuntu');

/************************************/
/*                                  */
/*    Comes with create-react-app   */
/*                                  */
/************************************/
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*******************************************/
/*                                         */
/*    Before revision and used on master   */
/*                                         */
/*******************************************/

.navbar_navbar{
  width: 100%;
  background-color: black;
}

.leftNav{
  display: flex;
  flex-grow: 1;
}

.rightNav{
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: 50%;
}

.navbarHome{
  color: #ccd6e3;
  text-decoration: none;
  display: inline-block;
  margin: 0 3%;
}

.navbarHome:hover{
  color: #F2F2F2;
  text-decoration: none;
}

.navbarHome:visited .navbarHome:active .navbarHome:link{
  text-decoration: none;
}

.navbarButton{
  color: #ccd6e3;
  text-decoration: none;
  display: inline-block;
  margin: 0 5%;
}
.navbarButton:hover{
  color: #F2F2F2;
  text-decoration: none;
  cursor: pointer;
}

.navbarButton:visited .navbarButton:active .navbarButton:link{
  text-decoration: none;
}



.homepage_section{
  width: 100%;
  height: 800px;
  margin: 0px !important;
  background-size: auto 100%;
  background-position: right top;
}

.flexSpacebetween {
  display: flex;
}

.drawerContainer {
  background-color: #323840;
  overflow: auto
}

/***************************/
/*                         */
/*    For the Entire App   */
/*                         */
/***************************/

button.defaultButton {
  background-color: white;
  font-size: 1.2em;
  text-transform: none;
  font-weight: normal;
}

/*********************************/
/*                               */
/*    For HomePageComponent.js   */
/*                               */
/*********************************/

div.homePage {
  padding-top: 15vh;
  /*background: #579;*/
  overflow: hidden;
  margin-top: -10vh;
}

.evenSlice {
  background-color: #ffffff;
}

.oddSlice {
  background-color: #F2F2F2;
}

/**************************************/
/*                                    */
/*    For HomePage/HomeInfoSlice.js   */
/*                                    */
/**************************************/

.HomeInfoSlice {
  /*padding-top: 2vh;*/
  /*padding-bottom: 2vh;*/
  padding: 2vh 15vw;
  /*background-color: #ffffff;*/
}

.HomeInfoSlice h2 {
  color: #172753;
  font-size: 3em;
  padding-bottom: 3vh;
}

.HomeInfoSlice p {
  font-size: 1.7em;
}

.homeImg{
  max-width: 100%;
  max-height: 30vh;
  object-fit: contain;
  margin: auto;

}

.leftHomeTxt {
  padding-right: 2.25vw;
}

.rightHomeTxt {
  padding-left: 2.25vw;
}

/*****************************************/
/*                                       */
/*    For HomePage/RecognitionSlice.js   */
/*                                       */
/*****************************************/

.RecognitionSlice {
  background-color: white;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.RecognitionSlice img {
  height: 10vh;
  width: auto;
  padding-left: 2vw;
  padding-right:2vw;
}

/***********************************/
/*                                 */
/*    For HomePage/TitleSlice.js   */
/*                                 */
/***********************************/

.TitleSlice {
  padding: 7vh 15vw;
  background-color: #172753;
}

.TitleSlice h1 {
  color: #ffffff;
  font-size: 5rem;
  font-style: oblique;
  padding-top: 4.5vh;
}

.TitleSlice h1 ~ p {
  font-size: 2.5rem;
  font-style: italic;
  color: #d9eff6;
}

/****************************************/
/*                                      */
/*    For HomePage/TripleIconSlice.js   */
/*                                      */
/****************************************/

.TripleIconSlice {
  padding: 0vh 15vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: #172753;
}

.tripleContent h2 {
  font-size: 2em;
  color: #ffffff;
  text-align: center;
}

.tripleContent {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.iconTrio {
  max-height: 12vh;
  max-width: 80%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

/************************************/
/*                                  */
/*    For Queue/QueueComponent.js   */
/*                                  */
/************************************/

.screenShade {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(34, 34, 34, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.pollingButton {
  position: relative;
  width: 15%;
  background-color: #d9edf7;
  box-shadow: none;
  border: none;
  outline: 1px solid black;
  border-radius: 10px 10px 0px 0px
}

.pollingButton:hover {
  background-color: #f7d9d9;
}

.pollingButton.stopped:hover {
  background-color: #83ff85;
}




.QueueTable {
  width: 80%;
  /* : #d9534f 1px solid; */
}

.jobInfoBox {
  background-color: rgb(255, 255, 255);
  width: 50%;
  height: 50%;
  z-index: 99;
  padding: 10px; /* Add padding for content */
  overflow: auto; /* Enable scrolling if needed */
  /* You can adjust these properties as needed */
  font-size: 14px;
  font-family: Arial, sans-serif;
  /* Ensure text wraps within the box */
  white-space: normal;
  word-wrap: break-word;
}
.historyPage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  padding-top: 15vh;
  padding-bottom: 15vh;
  align-items: center;
}

.QueueView {
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: white;
  flex-direction: column;
}

.Row {
  display: flex;
  flex-direction: column;
}

.Row:hover {
  background-color: rgb(172, 172, 172);
}

.Row.expanded {
  width: 100%;
  /* : 2px solid #00ff5e; */
  text-align: start;
}

.QueueView p {
  font-size: 1.25rem;
  /* : 2px solid #ff0000; */
}

.queueHeaderCell {
  background-color: #d9edf7;
  align-content: center;
  text-align: center !important;
  word-break: break-word;
  /* : 2px solid #ff0000; */
}

.queueHeaderCell p, .queueHeaderCell h1 {
  font-size: 1.75rem !important;
  color: #31708f;
  text-align: center;
  padding-top: .5vh;
  cursor: default;
  pointer-events: none;
  /* : 2px solid #ff0000; */
}

.queueBodyCell * {
  text-align: center;
  align-content: center;
  font-size: 1.2rem;
  vertical-align: middle;
  /* : 2px solid #ff0000; */
}

.queueBodyCell {
  word-break: break-word;
  /* : 2px solid #ff0000; */
}

.idCell{
  width: 8%;
}

.progressCell {
  width: 20%;
  display: inline-table;
}

.dateCell {
  width: 15%;
}

.speedCell {
  width: 12%;
}

.sourceCell {
  width: 15%;
}

.destinationCell {
  width: 15%;
}

.jobSizeCell {
  width: 10%;
}

.actionCell {
  width: 10%;
}

.mobileCell {
  width: 150vw;
  table-layout: fixed;
  text-align: left;
  font-size: 1.5rem;
  word-break: break-word;
}

.mobileCell * {
  font-size: 1.5rem;
}

.mobileHeaderCell {
  width: 100vw;
  align-content: center;
  text-align: center;
}

.mobileHeaderCell * {
  width: 100%;
  margin: auto;
}

.queueDropDown {
  margin: auto;
  padding-top: 2vh;
}

.queueBar {
  height: 20px !important;
  border-radius: 5px;
  width: 100%;
  display: block !important;
}

.queueBar * {
  background-color: #337ab7 !important;
}

.queueBarFailed * {
  background-color: #d9534f !important;
}

.queueBarLoading * {
  background-color: #f0ad4e !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #FFF4E3 !important;
}

.QueueProgressBar * {
}

.MuiTablePagination-toolbar {
  font-size: 1.5rem;
}

/**************************************/
/*                                    */
/*  For Transfer/TransferComponent.js */
/*                                    */
/**************************************/

.outeractionContainer{
  /*display: flex;*/
  /*flex-direction: row;*/
  padding-top: 15vh;
  justify-content: center;
  margin-top: -15vh;
  /*width: 100vw;*/
}

.actionContainer{
  max-width: 100vw !important;
  margin-top: 15px;
}

.wrapperBox{
  background-color: white;
  margin-bottom: 20px;
  height: auto;
  min-height: 85% !important;
  position: relative;
  box-shadow: 0 2px 2px gray;
  border: solid 1px lightgray;
}

.compactSwitch{
  /*width: 200px;*/
  /*right: 1%;*/
  /*position: absolute;*/
  margin-left: 50px;
  padding-top: 14px;
  padding-right: 10px;
}

.wrapperBoxLabel{
  color: black;
  font-size: 12px;
}

.innerBox{
  margin-top: 20px;
  padding: 20px;
}

.sendButton{
  background-color: #337ab7aa !important;
  font-size: 11px !important;
  margin-top: 5px !important;
  width: 50px !important;
  height: 35px !important;
  border-radius: 0 !important;
}
.sendButton:hover {
  background-color: #172753 !important;
  color:white !important
}
#sendFromRightToLeft{
  padding: 15px;
  margin-right: 10px;
}

#sendFromLeftToRight{
  padding: 15px;
  margin-left: 10px;
}

.boxHeader{
  padding: 50px 25px;
  background-color: #172753;
  color: white;
  min-height: 300px;
  font-size: 20px;
  font-weight: bold;
}

.transferGroup{
  border: solid 1px lightgray;
  width: auto;
  height: auto;
  overflow: hidden;
  /*margin-top: 30px;*/
}

.browseContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.browseButton{
  flex-grow: 1;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;
  /*padding-left: 30%;*/
}

.browseIcon{
  margin-right: 10px;
  font-size: 16px;
  width: 20px;
  text-align: left;
}


.transferSettings{
  border-top: solid 1px #172753;
  border-bottom: solid 1px #172753;
  color: #172753;
  padding: 10px;
  margin-bottom: 20px;
  /*border-radius: 5px;*/
  background-color: #F2F2F2;
}

.searchTextfield{
  background-color: white;
  border-radius: 0 !important;
}


.fileIcon{
  margin-top:11px;
  margin-left:10px
}
.fileName{
 margin-left: 10px;
  flex-grow: 1;
  text-align: left;
  font-size:16px;
  margin-top:8px
}
.fileAttribute{
 font-size: 12px;
  color: #444
}
@media only screen and (min-width: 800px){
  .boxHeader{
    text-align: center;
  }
}

.errorConsole{
  text-align: left;
  border: solid 1px #172753;
  height: 125px;
  overflow: scroll;
  word-wrap: break-word;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 2%;
  box-shadow: inset 0 1px 1px gray;
}

/*.instructions{*/
/*  font-size: 16px;*/
/*  box-shadow: 1px 1px 1px gray;*/
/*  border: solid 1px #172753;*/
/*  height: 125px;*/
/*  padding: 1% 1%;*/
/*  border-radius: 10px;*/
/*}*/

.terminalContainer{
  margin-bottom: 50px;

}

/**************************************/
/*                                    */
/*  For EndpointAuthenticateComponent.js */
/*                                    */
/**************************************/

.authenticationContainer{
  flex-grow: 1;
  flex-direction: column;
}

/**************************************/
/*                                    */
/*  For Terminal */
/*                                    */
/**************************************/

.terminalBar{
  background-color: #337ab7;
}

.terminalWrapper{
  height:300px;
  padding-left: 5px;
  padding-top: 5px;
  background-color: black;
  color:white;
  font-family: courier, serif;
  overflow: scroll;
}


/**************************************/
/*                                    */
/*  For Account */
/*                                    */
/**************************************/

.accCardStyle{
  width: 450px;
  border-radius: 1%;
  align-self: center;
}

@media only screen and (max-width: 640px) {
  .accCardStyle{
    align-self: flex-start;
  }
}
