/* Desktop */
@media screen and (min-width: 980px)  {
    .adjustTop{
        /* For Homepage, reverting padding-top applied to entire page using 
        .content class in MainComponent.css  */
        margin-top : -5%;
    }

    .adjustContent{
        padding-top: 5%;
    }
}

/* Tablet */
@media screen  and (max-width: 979px)  {
    .adjustTop{
        /* For Homepage, reverting padding-top applied to entire page using 
        .content class in MainComponent.css  */
        margin-top : -10%;
    }

    .adjustContent{
        padding-top: 10%;
    }

    img{
        max-height: 55vh !important
    }
}

/* Mobile */
@media screen and (max-width: 500px)  {
    .adjustTop{
        /* For Homepage, reverting padding-top applied to entire page using 
        .content class in MainComponent.css  */
        margin-top : -20%;
    }

    .adjustContent{
        padding-top: 35% !important;
    }

    .carouselStyle{
        height : 80% !important;
    }

    img{
        max-height: 80vh !important
    }
}


.content{
    /*height: 100vh;*/
    /*padding-bottom: 2%, */
}

.carouselStyle{
    height : 90%;
}

.carousel-control.right, .carousel-control.left{
    background-image: unset !important;
}

.carousel-indicators{
    bottom : -10% !important
}