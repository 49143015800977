body{
    background-image: linear-gradient(0deg, #ffffff 50%, #172753 50%); 
    height:90vh; 
    background-repeat: no-repeat; 
    background-attachment: fixed;
}

.support-form-wrapper {
    .MuiOutlinedInput-root {
        .MuiOutlinedInput-input {
            font-size: 14px;
        }
    }
    .MuiFormHelperText-root {
        font-size: 10px;
    }
}